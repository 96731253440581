import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import Layout from '../components/Layout';
import { API_URL } from '../variables';
import { UserContext } from '../components/UserContext';

const AccountSettings = () => {
  const { user } = useContext(UserContext); 
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(user?.email || ''); 
  const [adminId, setAdminId] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${API_URL}/admin/getAdmins`, {
          headers: {
            Authorization: `${token}`,
          },
        });

        console.log('API Response:', response.data); 

        if (Array.isArray(response.data)) {
          const admin = response.data.find(admin => admin.email === user.email);

          if (admin) {
            setFirstName(admin.firstName || '');
            setLastName(admin.lastName || '');
            setAdminId(admin.admin_id || '');
          } else {
            toast.error('Admin not found');
          }
        } else {
          toast.error('Unexpected response format');
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        toast.error('Failed to fetch user data');
      }
    };

    fetchUserData();
  }, [user.email]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('admin_id', adminId);

      await axios.post(`${API_URL}/admin/updateAdmin`, formData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('Failed to update profile');
      console.error('Error updating profile:', error);
    }
  };

  return (
    <Layout>
      <div className="flex min-h-screen">
        <div className="flex-1 p-6 bg-gray-50">
          <h1 className="text-2xl font-bold text-green-800 mb-6">Account Settings</h1>

          {/* Profile Update */}
          <div className="bg-white shadow-md rounded p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">Profile Information</h2>
            <form onSubmit={handleProfileUpdate}>
              <div className="mb-4">
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                  disabled 
                />
              </div>

              <button
                type="submit"
                className="bg-green-800 text-white px-4 py-2 rounded hover:bg-green-900"
              >
                Update Profile
              </button>
            </form>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default AccountSettings;
