import React, { useState } from 'react';
import Layout from '../components/Layout';

const ReportsPage = () => {
  const [reportType, setReportType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');

  return (
    <Layout>
      <div className="flex min-h-screen">
        <div className="flex-1 p-6 bg-gray-50">
          <h1 className="text-2xl font-bold text-green-800 mb-6">Reports</h1>
          <div className="bg-white shadow-md rounded p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">Generate Reports</h2>
            <div className="flex flex-row space-x-4">
              {/* Report Type Selection */}
              <div className="w-1/4 mb-4">
                <label htmlFor="reportType" className="block text-sm font-medium text-gray-700 mb-1">
                  Report Type
                </label>
                <select
                  id="reportType"
                  value={reportType}
                  onChange={(e) => setReportType(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="" disabled>Select a report type</option>
                  <option value="Booking">Booking</option>
                  <option value="Tours">Tours</option>
                  <option value="Users">Users</option>
                </select>
              </div>

              {/* Date Filters */}
              <div className="w-1/4 mb-4">
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="w-1/4 mb-4">
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              {/* Other Filters */}
              <div className="w-1/4 mb-4">
                <label htmlFor="userFilter" className="block text-sm font-medium text-gray-700 mb-1">
                  User Filter
                </label>
                <input
                  type="text"
                  id="userFilter"
                  value={userFilter}
                  onChange={(e) => setUserFilter(e.target.value)}
                  placeholder="Enter your Username"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="w-1/4 mb-4">
                <label htmlFor="locationFilter" className="block text-sm font-medium text-gray-700 mb-1">
                  Location Filter
                </label>
                <input
                  type="text"
                  id="locationFilter"
                  value={locationFilter}
                  onChange={(e) => setLocationFilter(e.target.value)}
                  placeholder="Enter location"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>

            {/* Placeholder Button for Generating Reports */}
            <button
              onClick={() => alert('Generate Report functionality coming soon!')}
              className="bg-green-800 text-white px-4 py-2 rounded hover:bg-green-900"
            >
              Generate Report
            </button>
          </div>

          {/* Placeholder for Generated Reports Section */}
          <div className="bg-white shadow-md rounded p-6">
            <h2 className="text-xl font-semibold mb-4">Generated Reports</h2>
            <p>No reports generated yet.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportsPage;
