import React, { useEffect, useState } from 'react';
import { FaSearch, FaEdit, FaTrashAlt } from 'react-icons/fa';
import AddUser from './AddUser';
import Loader from '../components/Loader';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import EditUserModal from './EditAdmin';
import DeleteConfirmationModal from './Delete';
import axios from 'axios';

const api_url = `${API_URL}/admin/getAdmins`;
const delete_url = `${API_URL}/admin/deleteAdmin`;
const update_url = `${API_URL}/admin/updateAdmin`;

const UserTable = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [adminToEdit, setAdminToEdit] = useState(null);
  const [adminToDelete, setAdminToDelete] = useState(null); 
  const [loading, setLoading] = useState(true);

  // Fetch admins
  useEffect(() => {
    const fetchAdmins = async () => {
      setLoading(true);
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
          },
        });

        setAdmins(response.data);
      } catch (error) {
        alert('Error: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAdmins();
  }, []);
  const handleDelete = (admin) => {
    setAdminToDelete(admin);
    setIsDeleteModalOpen(true); 
  };

  const confirmDeleteAdmin = async () => {
    try {
      const token = getToken();  
      await axios.post(delete_url, 
        { admin_id: adminToDelete.admin_id },  
        { headers: { 'Authorization': `${token}`} }  
      );
      setAdmins(admins.filter(admin => admin.admin_id !== adminToDelete.admin_id));
      setIsDeleteModalOpen(false);  
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };
  

  const handleEdit = (admin) => {
    setAdminToEdit(admin); 
    setIsEditModalOpen(true); 
  };

  const updateAdmin = async (updatedAdmin) => {
    try {
      const token = getToken();
      await axios.post(update_url, {
        admin_id: updatedAdmin.admin_id,
        firstName: updatedAdmin.firstName,
        lastName: updatedAdmin.lastName,
        email: updatedAdmin.email,
      }, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });
      setAdmins((prevAdmins) =>
        prevAdmins.map((admin) =>
          admin.admin_id === updatedAdmin.admin_id ? updatedAdmin : admin
        )
      );
      setIsEditModalOpen(false); 
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-green-800">Users List</h2>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center mb-6">
            <div className="relative w-1/2">
              <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-green-800" />
              <input
                type="text"
                placeholder="Search"
                className="border border-green-800 pl-10 p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-green-700"
              />
            </div>
            <button
              onClick={() => setIsAddModalOpen(true)}
              className="bg-green-800 text-yellow-300 px-4 py-2 rounded hover:bg-green-600 flex items-center"
            >
              Add user <span className="ml-2 text-xl">+</span>
            </button>
          </div>
          <table className="w-full table-auto border-collapse">
            <thead className="text-green-800">
              <tr>
                <th className="border-b py-3 px-4 text-left">Name</th>
                <th className="border-b py-3 px-4 text-left">Create Date</th>
                <th className="border-b py-3 px-4 text-left">Role</th>
                <th className="border-b py-3 px-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              {admins.map((admin) => (
                <tr key={admin.admin_id}>
                  <td className="border-b py-3 px-4">
                    {admin.firstName} {admin.lastName}
                  </td>
                  <td className="border-b py-3 px-4">
                    {new Date(admin.createdAt).toLocaleDateString()}
                  </td>
                  <td className="border-b py-3 px-4">Admin</td>
                  <td className="border-b py-3 px-4">
                    <button
                      className="text-green-800"
                      onClick={() => handleEdit(admin)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="text-yellow-400 ml-3"
                      onClick={() => handleDelete(admin)}
                    >
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {/* Modals */}
      <AddUser isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} />
      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        admin={adminToEdit}
        onSave={updateAdmin}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDeleteAdmin}
        admin={adminToDelete}
      />
    </div>
  );
};

export default UserTable;
