import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';
import { API_URL } from '../variables'; 
import { getToken } from '../authService';
import Loader from '../components/Loader';

const api_url = `${API_URL}/contacts/getAllContacts`;
const ContactUs = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const token = getToken(); 
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `${token}`, 
            'Content-Type': 'application/json',
          },
        });
        setContacts(response.data);
      } catch (err) {
        setError('Failed to fetch submissions.');
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const renderContent = () => {
    if (loading) return <Loader />;  
    if (error) return <div>{error}</div>;

    return (
      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-green-800 text-white">
            <tr>
            <th className="px-4 py-2 text-left">ID</th>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Email</th>
              <th className="px-4 py-2 text-left">Phone Number</th>
              <th className="px-4 py-2 text-left">Nationality</th>
              <th className="px-4 py-2 text-left">Message</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id} className="border-t border-gray-200">
                <td className="px-4 py-2">{contact.id}</td>
                <td className="px-4 py-2">{contact.names}</td>
                <td className="px-4 py-2">{contact.email}</td>
                <td className="px-4 py-2">{contact.phone}</td>
                <td className="px-4 py-2">{contact.nationality}</td>
                <td className="px-4 py-2">{contact.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Layout>
      <div className="mx-auto p-6 bg-gray-50">
        <h1 className="text-2xl font-bold text-green-800 mb-6">Contact Submissions</h1>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default ContactUs;
