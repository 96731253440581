import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import TourList from "../components/TourList";
import TourForm from "../components/TourForm";
import Layout from "../components/Layout";

const csvFilePath = require("../assets/tours.csv");

const Tours = () => {
  const [tours, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);

  useEffect(() => {
    const fetchTours = async () => {
      const response = await fetch(csvFilePath);
      const reader = response.body.getReader();
      const result = await reader.read();
      const decoder = new TextDecoder("utf-8");
      const csv = decoder.decode(result.value);
      const parsedData = Papa.parse(csv, { header: true }).data;
      setTours(parsedData);
    };

    fetchTours();
  }, []);

  const handleEdit = (tour) => {
    setSelectedTour(tour);
    setIsFormOpen(true); 
  };

  const handleDelete = (id) => {
    const updatedTours = tours.filter((tour) => tour.id !== id);
    setTours(updatedTours);
  };

  const handleSave = (newTour) => {
    if (selectedTour) {
      const updatedTours = tours.map((tour) =>
        tour.id === selectedTour.id ? newTour : tour
      );
      setTours(updatedTours);
    } else {
      setTours([newTour, ...tours]);
    }
    setSelectedTour(null);
    setIsFormOpen(false); 
  };

  const openNewTourForm = () => {
    setSelectedTour(null);
    setIsFormOpen(true); 
  };

  const closeForm = () => {
    setSelectedTour(null);
    setIsFormOpen(false); 
  };

  return (
    <Layout>
      <div className="container mx-auto pt-6">
        {isFormOpen ? (
          <div>
            <h2 className="text-2xl text-green-800 font-bold mb-6">
              {selectedTour ? "Edit Tour" : "Add New Tour"}
            </h2>
            <TourForm
              tour={selectedTour}
              onSave={handleSave}
              onClose={closeForm}
            />
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl text-green-800 font-bold">List of Tours</h2>
              <button
                className="bg-green-800 text-white px-4 py-2 rounded-md hover:bg-green-700 transition duration-300"
                onClick={openNewTourForm}
              >
                Add New Tour
              </button>
            </div>
            <TourList tours={tours} onEdit={handleEdit} onDelete={handleDelete} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Tours;
