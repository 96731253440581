// src/screens/SignInPage.js
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { API_URL } from "../variables";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";


const api_url = `${API_URL}/auth/login`;

// Zod schema for form validation
const schema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(5, "Password must be at least 5 characters"),
});

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useContext(UserContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        api_url,
        JSON.stringify(data),
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const { token } = response.data;
      const { email } = data; 

      toast.success("Sign-in successful!",{ duration: 500 });
      login({ token, email });
      localStorage.setItem("authToken", token);
      localStorage.setItem("userEmail", email);
      setTimeout(() => {
        navigate("/dashboard");
      }, 500);
      
    } catch (error) {
      toast.error("Sign-in failed. Please check your credentials.");
      console.error("Error signing in:", error);
    }
  };

  return (
    <div className="flex flex-1 w-full items-center justify-center min-h-screen bg-gray-100">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white px-6 py-6 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-6 text-center">Sign In</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Email Field */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              {...register("email")}
              className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-800"
            />
            {errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p>}
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              {...register("password")}
              className="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-green-800"
            />
            {errors.password && <p className="text-red-500 text-xs italic">{errors.password.message}</p>}
          </div>
          <button
            type="submit"
            className="w-full bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
          >
            Sign In
          </button>
        </form>
        <div className="text-center my-4">
          <p className="text-gray-500">or</p>
        </div>
        <button
          className="w-full bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline flex items-center justify-center"
        >
          <img
            src="https://img.icons8.com/color/16/000000/google-logo.png"
            alt="Google logo"
            className="mr-2"
          />
          Sign in with Google
        </button>
      </div>
    </div>
  );
};

export default SignIn;
