import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaUsers, FaMap, FaCog, FaUser, FaFile, FaInfo, FaBars, FaTimes } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <>
      {/* Toggle button (Menu or Close) */}
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-20 p-2 text-green-800 md:hidden"
      >
      <FaBars className="text-2xl " />
      </button>

      {/* Sidebar Drawer */}
      <div
        className={`fixed top-0 left-0 bg-white w-64 min-h-screen border-r border-gray-200 z-10 transition-transform duration-300 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0`}
      >
        <div className="py-4 px-5 flex flex-col">
          {/* Logo */}
          <div className="text-2xl text-green-800 pb-6 text-center" style={{ fontFamily: 'Impact, sans-serif' }}>
            SONG & STARK TOURS
          </div>

          {/* Navigation Links */}
          <ul className="space-y-2 flex-grow">
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaTachometerAlt className="text-lg mr-3" /> Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/bookings"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaUsers className="text-lg mr-3" /> Bookings
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tours"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaMap className="text-lg mr-3" /> Tour Packages
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaInfo className="text-lg mr-3" /> Contacts Inquiries
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaUser className="text-lg mr-3" /> Users
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reports"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaFile className="text-lg mr-3" /> Reports
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/account"
                className={({ isActive }) =>
                  `flex items-center text-base p-2 font-bold ${
                    isActive ? 'text-green-800' : 'text-black'
                  } hover:text-green-800`
                }
              >
                <FaCog className="text-lg mr-3" /> Account Settings
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      {/* Overlay for the drawer */}
      {isOpen && (
        <div className="fixed inset-0 bg-black opacity-50 z-0" onClick={toggleSidebar} />
      )}
    </>
  );
};

export default Sidebar;
