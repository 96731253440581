import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Activities from '../components/Activities';
import Destinations from '../components/Destinations';
import axios from 'axios';
import { API_URL } from '../variables'; 
import { getToken } from '../authService'; 
import { Link } from 'react-router-dom';
import Loader from '../components/Loader'; 
import { FaSuitcase, FaCalendarAlt, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'; 

function Dashboard() {
  const [pendingBookingsCount, setPendingBookingsCount] = useState(0);
  const [pendingReservationCount, setPendingReservationCount] = useState(0);
  const [totalTours, setTotalTours] = useState(0);
  const [contactRequests, setContactRequests] = useState(0);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = getToken(); 

        const headers = {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        };
        const { data: packages } = await axios.get(`${API_URL}/package/getAllPackages`, { headers });
        setTotalTours(packages.length);

        const { data: customReservations } = await axios.get(`${API_URL}/custom_reservations/getAllReservations`, { headers });
        setPendingBookingsCount(customReservations.length);

        const { data: reservations } = await axios.get(`${API_URL}/reservations/getAllReservations`, { headers });
        setPendingReservationCount(reservations.length);

        const { data: contacts } = await axios.get(`${API_URL}/contacts/getAllContacts`, { headers });
        setContactRequests(contacts.length);

        setLoading(false); 

      } catch (err) {
        setError('Failed to load dashboard data.');
        console.error(err);
        setLoading(false); 
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <Loader />; 
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Layout>
      <div className="container mx-auto p-6">
      <h1 className="text-2xl text-green-800 font-bold mb-6 text-center">Dashboard</h1>
      <div className="bg-green-50 p-6 rounded-lg mb-8">
          <h1 className="text-xl font-bold text-green-800">Welcome to your Dashboard!</h1>
          <p className="text-sm text-green-700">Here's a quick summary of your current activities and interactions.</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
        <Link to="/tours">
            <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center">
              <FaMapMarkerAlt className="text-green-800 text-xl mr-4" /> {/* Smaller Icon */}
              <div>
                <h2 className="text-base font-medium text-gray-600">Total Tours</h2>
                <p className="text-green-800 text-3xl mt-3 font-bold">{totalTours}</p>
              </div>
            </div>
          </Link>
          <Link to="/bookings">
            <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center">
              <FaSuitcase className="text-green-800 text-xl mr-4" /> {/* Smaller Icon */}
              <div>
                <h2 className="text-base font-medium text-gray-600">Custom Reservations</h2>
                <p className="text-green-800 text-3xl mt-3 font-bold">{pendingBookingsCount}</p>
              </div>
            </div>
          </Link>
          <Link to="/bookings">
            <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center">
              <FaCalendarAlt className="text-green-800 text-xl mr-4" /> {/* Smaller Icon */}
              <div>
                <h2 className="text-base font-medium text-gray-600">Reservations</h2>
                <p className="text-green-800 text-3xl mt-3 font-bold">{pendingReservationCount}</p>
              </div>
            </div>
          </Link>
          <Link to="/contact">
            <div className="bg-white shadow-lg rounded-xl p-6 hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center">
              <FaPhoneAlt className="text-green-800 text-xl mr-4" /> {/* Smaller Icon */}
              <div>
                <h2 className="text-base font-medium text-gray-600">Contact Requests</h2>
                <p className="text-green-800 text-3xl mt-3 font-bold">{contactRequests}</p>
              </div>
            </div>
          </Link>
        </div>

        {/* Activities and Destinations */}
        <div className="space-y-10">
          <Activities />
          <Destinations />
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
